module Main exposing (main)

import AdditionalText
import Exception
import Html exposing (Html)
import Html.Attributes
import Http
import Lightbox
import Locale
import Navigation
import Section.Cards
import Section.Carousel
import Section.ContactForm
import Section.FacebookPage
import Section.FeatureGridList
import Section.IconTextColumns
import Section.ImageList
import Section.ImageWithFrontText
import Section.ImageWithText
import Section.InfoText
import Section.Navigation
import Section.NewsletterSignup
import Section.Search
import Section.Section
import Section.SimpleCentered
import Section.SimpleThreeColumn
import Section.Slider
import Section.Space
import Section.Text
import Section.Timeline
import Strapi.Image
import Strapi.Link
import Strapi.MediaSettings exposing (Fit(..), Format(..))
import Strapi.Menu
import Strapi.SectionSettings
import Strapi.Slug
import UIExplorer exposing (UIExplorerProgram, logoFromUrl)
import Url exposing (Url)


type alias Model =
    { additionalText : AdditionalText.Model
    , carousel : Section.Carousel.Model
    , carouselWithTimeout : Section.Carousel.Model
    , contactForm : Section.ContactForm.Model
    , contactFormWithHeader : Section.ContactForm.Model
    , lightbox : Lightbox.Model
    , navigation : Navigation.Model
    , newsletterSignup : Section.NewsletterSignup.Model
    , newsletterSignupWithHeader : Section.NewsletterSignup.Model
    , search : Section.Search.Model
    }


initialModel : Model
initialModel =
    { additionalText = AdditionalText.init Nothing
    , carousel =
        Section.Carousel.initialModel
            { images = nonEmptyCaptionedImages
            , mediaSettings = Strapi.MediaSettings.default
            , settings = Strapi.SectionSettings.default
            , timeoutMs = Nothing
            }
    , carouselWithTimeout =
        Section.Carousel.initialModel
            { images = nonEmptyCaptionedImages
            , mediaSettings = Strapi.MediaSettings.default
            , settings = Strapi.SectionSettings.default
            , timeoutMs = Just 1000
            }
    , contactForm =
        Section.ContactForm.initialModel
            { labels = { email = "E-Mail-Adresse", name = "Name", submit = "Senden", text = "Sagen Sie uns etwas!" }
            , sectionHeader = Nothing
            , settings = Strapi.SectionSettings.default
            }
    , contactFormWithHeader =
        Section.ContactForm.initialModel
            { labels = { email = "E-Mail-Adresse", name = "Name", submit = "Senden", text = "Sagen Sie uns etwas!" }
            , sectionHeader =
                Just
                    { textField = headerTextField
                    , visible = True
                    }
            , settings = Strapi.SectionSettings.default
            }
    , lightbox = Lightbox.init Nothing
    , navigation =
        Navigation.init
            { currentUrl = currentUrl
            , menu = menu
            , toUrl = toUrl
            }
    , newsletterSignup =
        Section.NewsletterSignup.initialModel
            { sectionHeader = Nothing
            , settings = Strapi.SectionSettings.default
            }
    , newsletterSignupWithHeader =
        Section.NewsletterSignup.initialModel
            { sectionHeader =
                Just
                    { textField = headerTextField
                    , visible = True
                    }
            , settings = Strapi.SectionSettings.default
            }
    , search =
        Section.Search.initialModel
            { query = ""
            }
            { settings = Strapi.SectionSettings.default
            }
    }


menu : Strapi.Menu.Menu
menu =
    { groups =
        [ Strapi.Menu.Group (Strapi.Menu.Headline1 Nothing)
            [ Strapi.Menu.SingleLink (internalLink "Navigation" "Drawer/Default" "a")
            , Strapi.Menu.SingleLink (externalLink "https://duckduckgo.com" "b")
            ]
        , Strapi.Menu.Group (Strapi.Menu.Headline1 (Just "c"))
            [ Strapi.Menu.SingleLink (internalLink "Navigation" "Drawer/Default" "d")
            , Strapi.Menu.LinkGroup
                (Strapi.Menu.Headline2 (Strapi.Menu.Headline1 (Just "c")) "e")
                [ internalLink "Navigation" "Drawer/Default" "f"
                , internalLink "Navigation" "Drawer/Default" "g"
                ]
            , Strapi.Menu.SingleLink (internalLink "Navigation" "Drawer/Default" "h")
            ]
        ]
    }


toUrl : Strapi.Slug.Slug -> Url
toUrl slug =
    { fragment = Nothing
    , host = "localhost"
    , path = Strapi.Slug.toPath slug
    , port_ = Nothing
    , protocol = Url.Http
    , query = Nothing
    }


type Msg
    = AdditionalTextOpened String
    | Ignored
    | LightboxOpened Lightbox.Handle
    | UpdateAdditionalText AdditionalText.Msg
    | UpdateCarousel Section.Carousel.Msg
    | UpdateCarouselWithTimeout Section.Carousel.Msg
    | UpdateContactForm Section.ContactForm.Msg
    | UpdateLightbox Lightbox.Msg
    | UpdateNavigation Navigation.Msg
    | UpdateNewsletterSignup Section.NewsletterSignup.Msg
    | UpdateSearch Section.Search.Msg


update : Msg -> Model -> Model
update msg model =
    case msg of
        AdditionalTextOpened content ->
            { model
                | additionalText = AdditionalText.init (Just content)
            }

        Ignored ->
            model

        LightboxOpened handle ->
            { model
                | lightbox = Lightbox.init (Just handle)
            }

        UpdateAdditionalText msg_ ->
            { model
                | additionalText = AdditionalText.update msg_ model.additionalText
            }

        UpdateCarousel msg_ ->
            { model
                | carousel =
                    Section.Carousel.update msg_ model.carousel
                        |> Tuple.first
            }

        UpdateCarouselWithTimeout msg_ ->
            { model
                | carouselWithTimeout =
                    Section.Carousel.update msg_ model.carouselWithTimeout
                        |> Tuple.first
            }

        UpdateContactForm msg_ ->
            { model
                | contactForm =
                    Section.ContactForm.update
                        { task = \_ -> ()
                        }
                        msg_
                        model.contactForm
                        |> Tuple.first
            }

        UpdateLightbox msg_ ->
            { model
                | lightbox = Lightbox.update msg_ model.lightbox
            }

        UpdateNavigation msg_ ->
            { model
                | navigation = Navigation.update msg_ model.navigation
            }

        UpdateNewsletterSignup msg_ ->
            { model
                | newsletterSignup =
                    Section.NewsletterSignup.update
                        { host = "http://localhost:1234"
                        }
                        { task = \_ -> ()
                        }
                        msg_
                        model.newsletterSignup
                        |> Tuple.first
            }

        UpdateSearch msg_ ->
            { model
                | search =
                    Section.Search.update
                        { navigate = \_ -> ()
                        , replaceUrl = \_ -> ()
                        }
                        msg_
                        model.search
                        |> Tuple.first
            }


main : UIExplorerProgram Model Msg {} {}
main =
    UIExplorer.exploreWithCategories
        { customHeader =
            Just
                { bgColor = Just "#ffffff"
                , logo = logoFromUrl ""
                , title = "Design Explorer"
                , titleColor = Just "#000000"
                }
        , customModel = initialModel
        , documentTitle = Nothing
        , enableDarkMode = True
        , init = \_ -> identity
        , menuViewEnhancer = \_ -> identity
        , onModeChanged = Nothing
        , subscriptions =
            \model ->
                Sub.batch
                    [ Sub.map UpdateCarousel
                        (Section.Carousel.subscriptions model.customModel.carousel)
                    , Sub.map UpdateCarouselWithTimeout
                        (Section.Carousel.subscriptions model.customModel.carouselWithTimeout)
                    ]
        , update =
            \msg model ->
                ( { model
                    | customModel = update msg model.customModel
                  }
                , Cmd.none
                )
        , viewEnhancer =
            \{ customModel } node ->
                Html.div []
                    [ node
                    , AdditionalText.view customModel.additionalText
                        |> Html.map (UpdateAdditionalText >> UIExplorer.ExternalMsg)
                    , Lightbox.view customModel.lightbox
                        |> Html.map (UpdateLightbox >> UIExplorer.ExternalMsg)
                    ]
        }
        (UIExplorer.createCategories
            |> UIExplorer.category "Pages"
                [ UIExplorer.storiesOf
                    "Example Page 1"
                    [ ( "Full size"
                      , \_ ->
                            examplePage1
                      , {}
                      )
                    ]
                , UIExplorer.storiesOf
                    "Example Page 2"
                    [ ( "Full size"
                      , \_ ->
                            examplePage2
                      , {}
                      )
                    ]
                , let
                    error =
                        "Windows Update Error 0x80070057."

                    url locale =
                        { fragment = Just ("Pages/Exception/Not Found (" ++ Locale.toString locale ++ ")")
                        , host = "https://localhost:1234"
                        , path = "/"
                        , port_ = Nothing
                        , protocol = Url.Http
                        , query = Nothing
                        }
                  in
                  UIExplorer.storiesOf
                    "Exception"
                    (List.concatMap
                        (\locale ->
                            [ ( "Crashed (" ++ Locale.toString locale ++ ")"
                              , \_ ->
                                    Exception.view
                                        { fullScreen = False
                                        , locale = locale
                                        }
                                        (Exception.Crashed error)
                              , {}
                              )
                            , ( "Not Found (" ++ Locale.toString locale ++ ")"
                              , \_ ->
                                    Exception.view
                                        { fullScreen = False
                                        , locale = Locale.De
                                        }
                                        (Exception.NotFound (url locale))
                              , {}
                              )
                            , ( "Not Loaded (" ++ Locale.toString locale ++ ")"
                              , \_ ->
                                    Exception.view
                                        { fullScreen = False
                                        , locale = Locale.De
                                        }
                                        (Exception.NotLoaded error (url locale))
                              , {}
                              )
                            ]
                        )
                        [ Locale.De
                        , Locale.En
                        ]
                    )
                ]
            |> UIExplorer.category "Navigation"
                [ UIExplorer.storiesOf
                    "Drawer"
                    [ ( "Default"
                      , \{ customModel } ->
                            Navigation.view
                                { currentUrl = currentUrl
                                , menu = menu
                                , toUrl = toUrl
                                }
                                customModel.navigation
                                |> Html.map UpdateNavigation
                      , {}
                      )
                    ]
                ]
            |> UIExplorer.category "Sections"
                [ let
                    cards =
                        List.map
                            (\image_ ->
                                { buttonLabel = Just "Visit"
                                , image = image_
                                , link = Nothing
                                , textField = cardText
                                }
                            )
                            images
                  in
                  UIExplorer.storiesOf
                    "Cards"
                    [ ( "Default"
                      , \_ ->
                            Section.Cards.view
                                { cards =
                                    List.map
                                        (\card ->
                                            { card
                                                | link = Just "/#Sections/Cards/Linked"
                                            }
                                        )
                                        cards
                                , layoutStyle = Section.Cards.Gallery
                                , mediaSettings = Strapi.MediaSettings.default
                                , sectionHeader = Nothing
                                , settings = Strapi.SectionSettings.default
                                , style = Section.Cards.Elevated
                                }
                      , {}
                      )
                    , ( "Outlined"
                      , \_ ->
                            Section.Cards.view
                                { cards =
                                    List.map
                                        (\card ->
                                            { card
                                                | link = Just "/#Sections/Cards/Linked"
                                            }
                                        )
                                        cards
                                , layoutStyle = Section.Cards.Gallery
                                , mediaSettings = Strapi.MediaSettings.default
                                , sectionHeader = Nothing
                                , settings = Strapi.SectionSettings.default
                                , style = Section.Cards.Outlined
                                }
                      , {}
                      )
                    , ( "Borderless"
                      , \_ ->
                            Section.Cards.view
                                { cards =
                                    List.map
                                        (\card ->
                                            { card
                                                | link = Just "/#Sections/Cards/Linked"
                                            }
                                        )
                                        cards
                                , layoutStyle = Section.Cards.Gallery
                                , mediaSettings = Strapi.MediaSettings.default
                                , sectionHeader = Nothing
                                , settings = Strapi.SectionSettings.default
                                , style = Section.Cards.Borderless
                                }
                      , {}
                      )
                    , ( "Linked"
                      , \_ ->
                            Section.Cards.view
                                { cards =
                                    List.map
                                        (\card ->
                                            { card
                                                | link = Just "/#Sections/Cards/Linked"
                                            }
                                        )
                                        cards
                                , layoutStyle = Section.Cards.Gallery
                                , mediaSettings = Strapi.MediaSettings.default
                                , sectionHeader = Nothing
                                , settings = Strapi.SectionSettings.default
                                , style = Section.Cards.Elevated
                                }
                      , {}
                      )
                    , ( "Contain"
                      , \_ ->
                            Section.Cards.view
                                { cards = cards
                                , layoutStyle = Section.Cards.Gallery
                                , mediaSettings =
                                    { fit = Contain
                                    , format = Format_3_2
                                    }
                                , sectionHeader = Nothing
                                , settings = Strapi.SectionSettings.default
                                , style = Section.Cards.Elevated
                                }
                      , {}
                      )
                    , ( "Logo"
                      , \_ ->
                            Section.Cards.view
                                { cards = cards
                                , layoutStyle = Section.Cards.Gallery
                                , mediaSettings =
                                    { fit = Logo
                                    , format = Format_3_2
                                    }
                                , sectionHeader = Nothing
                                , settings = Strapi.SectionSettings.default
                                , style = Section.Cards.Elevated
                                }
                      , {}
                      )
                    , ( "Format 1:1"
                      , \_ ->
                            Section.Cards.view
                                { cards = cards
                                , layoutStyle = Section.Cards.Gallery
                                , mediaSettings =
                                    { fit = Cover
                                    , format = Format_1_1
                                    }
                                , sectionHeader = Nothing
                                , settings = Strapi.SectionSettings.default
                                , style = Section.Cards.Elevated
                                }
                      , {}
                      )
                    , ( "Format 16:9"
                      , \_ ->
                            Section.Cards.view
                                { cards = cards
                                , layoutStyle = Section.Cards.Gallery
                                , mediaSettings =
                                    { fit = Cover
                                    , format = Format_16_9
                                    }
                                , sectionHeader = Nothing
                                , settings = Strapi.SectionSettings.default
                                , style = Section.Cards.Elevated
                                }
                      , {}
                      )
                    , ( "With Header"
                      , \_ ->
                            Section.Cards.view
                                { cards =
                                    List.map
                                        (\card ->
                                            { card
                                                | link = Just "/#Sections/Cards/Linked"
                                            }
                                        )
                                        cards
                                , layoutStyle = Section.Cards.Gallery
                                , mediaSettings = Strapi.MediaSettings.default
                                , sectionHeader =
                                    Just
                                        { textField = headerTextField
                                        , visible = True
                                        }
                                , settings = Strapi.SectionSettings.default
                                , style = Section.Cards.Elevated
                                }
                      , {}
                      )
                    , ( "layoutStyle: SliderOnMobile"
                      , \_ ->
                            Section.Cards.view
                                { cards =
                                    List.map
                                        (\card ->
                                            { card
                                                | link = Just "/#Sections/Cards/Linked"
                                            }
                                        )
                                        cards
                                , layoutStyle = Section.Cards.SliderOnMobile
                                , mediaSettings = Strapi.MediaSettings.default
                                , sectionHeader = Nothing
                                , settings = Strapi.SectionSettings.default
                                , style = Section.Cards.Elevated
                                }
                      , {}
                      )
                    ]
                , UIExplorer.storiesOf
                    "Carousel"
                    [ ( "Default"
                      , \{ customModel } ->
                            Section.Carousel.view
                                customModel.carousel
                                |> Html.map UpdateCarousel
                      , {}
                      )
                    , ( "With timeout"
                      , \{ customModel } ->
                            Section.Carousel.view
                                customModel.carouselWithTimeout
                                |> Html.map UpdateCarousel
                      , {}
                      )
                    ]
                , let
                    data =
                        { labels =
                            { email = "E-Mail-Adresse"
                            , name = "Name"
                            , submit = "Senden"
                            , text = "Sagen Sie uns etwas!"
                            }
                        , sectionHeader = Nothing
                        , settings = Strapi.SectionSettings.default
                        }
                  in
                  UIExplorer.storiesOf
                    "Contact Form"
                    [ ( "Default"
                      , \{ customModel } ->
                            Section.ContactForm.view
                                { locale = Locale.De
                                }
                                customModel.contactForm
                                |> Html.map UpdateContactForm
                      , {}
                      )
                    , ( "With Header"
                      , \{ customModel } ->
                            Section.ContactForm.view
                                { locale = Locale.De
                                }
                                customModel.contactFormWithHeader
                                |> Html.map UpdateContactForm
                      , {}
                      )
                    , ( "Submitting"
                      , \{ customModel } ->
                            Section.ContactForm.view
                                { locale = Locale.De
                                }
                                (Section.ContactForm.Model data
                                    { input = Section.ContactForm.initialInput
                                    , lastSubmission = Nothing
                                    , showValidationErrors = False
                                    , submitting = True
                                    }
                                )
                                |> Html.map (\_ -> Ignored)
                      , {}
                      )
                    , ( "Validating"
                      , \{ customModel } ->
                            Section.ContactForm.view
                                { locale = Locale.De
                                }
                                (Section.ContactForm.Model data
                                    { input = Section.ContactForm.initialInput
                                    , lastSubmission = Nothing
                                    , showValidationErrors = True
                                    , submitting = False
                                    }
                                )
                                |> Html.map (\_ -> Ignored)
                      , {}
                      )
                    , ( "Success"
                      , \{ customModel } ->
                            Section.ContactForm.view
                                { locale = Locale.De
                                }
                                (Section.ContactForm.Model data
                                    { input = Section.ContactForm.initialInput
                                    , lastSubmission = Just (Ok ())
                                    , showValidationErrors = False
                                    , submitting = False
                                    }
                                )
                                |> Html.map (\_ -> Ignored)
                      , {}
                      )
                    , ( "Error"
                      , \{ customModel } ->
                            Section.ContactForm.view
                                { locale = Locale.De
                                }
                                (Section.ContactForm.Model data
                                    { input = Section.ContactForm.initialInput
                                    , lastSubmission = Just (Err Http.NetworkError)
                                    , showValidationErrors = False
                                    , submitting = False
                                    }
                                )
                                |> Html.map (\_ -> Ignored)
                      , {}
                      )
                    ]
                , UIExplorer.storiesOf
                    "Facebook page"
                    [ ( "Default"
                      , \_ ->
                            Section.FacebookPage.view
                                { height = 500, width = 800 }
                                { facebookUrl = "https://www.facebook.com/woc2023"
                                , settings =
                                    { htmlId = Just "facebook-page"
                                    , visible = True
                                    }
                                }
                      , {}
                      )
                    ]
                , let
                    shortDescription =
                        "Lorem ipsum dolor sit amet"

                    longDescription =
                        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."

                    features n =
                        List.map
                            (\description ->
                                { description = description
                                , icon = icon
                                , name = "Lorem ipsum"
                                }
                            )
                            (List.take n
                                (List.concat
                                    (List.repeat (ceiling (toFloat n / 2))
                                        [ shortDescription
                                        , longDescription
                                        ]
                                    )
                                )
                            )
                  in
                  UIExplorer.storiesOf
                    "Feature Grid List"
                    [ ( "Max2Columns"
                      , \_ ->
                            Section.FeatureGridList.view
                                { features = features 5
                                , sectionHeader = Nothing
                                , settings = Strapi.SectionSettings.default
                                , style = Section.FeatureGridList.Max2Columns
                                }
                      , {}
                      )
                    , ( "Max4Columns"
                      , \_ ->
                            Section.FeatureGridList.view
                                { features = features 10
                                , sectionHeader = Nothing
                                , settings = Strapi.SectionSettings.default
                                , style = Section.FeatureGridList.Max4Columns
                                }
                      , {}
                      )
                    , ( "With Header"
                      , \_ ->
                            Section.FeatureGridList.view
                                { features = features 5
                                , sectionHeader =
                                    Just
                                        { textField = headerTextField
                                        , visible = True
                                        }
                                , settings = Strapi.SectionSettings.default
                                , style = Section.FeatureGridList.Max2Columns
                                }
                      , {}
                      )
                    ]
                , UIExplorer.storiesOf
                    "Icon Text Columns"
                    [ ( "Default"
                      , \_ ->
                            Section.IconTextColumns.view
                                { columns =
                                    List.repeat 3
                                        { icon = icon
                                        , text = iconTextColumnsText
                                        }
                                , sectionHeader = Nothing
                                , settings = Strapi.SectionSettings.default
                                }
                      , {}
                      )
                    , ( "With Header"
                      , \_ ->
                            Section.IconTextColumns.view
                                { columns =
                                    List.repeat 3
                                        { icon = icon
                                        , text = iconTextColumnsText
                                        }
                                , sectionHeader =
                                    Just
                                        { textField = headerTextField
                                        , visible = True
                                        }
                                , settings = Strapi.SectionSettings.default
                                }
                      , {}
                      )
                    ]
                , UIExplorer.storiesOf
                    "Image List"
                    [ ( "Standard"
                      , \_ ->
                            Section.ImageList.view
                                { onClick = Just LightboxOpened
                                }
                                { images = nonEmptyCaptionedImages
                                , listType = Section.ImageList.Standard
                                , sectionHeader = Nothing
                                , settings = Strapi.SectionSettings.default
                                }
                      , {}
                      )
                    , ( "Masonry"
                      , \_ ->
                            Section.ImageList.view
                                { onClick = Just LightboxOpened
                                }
                                { images = nonEmptyCaptionedImages
                                , listType = Section.ImageList.Masonry
                                , sectionHeader = Nothing
                                , settings = Strapi.SectionSettings.default
                                }
                      , {}
                      )
                    , ( "With Header"
                      , \_ ->
                            Section.ImageList.view
                                { onClick = Just LightboxOpened
                                }
                                { images = nonEmptyCaptionedImages
                                , listType = Section.ImageList.Standard
                                , sectionHeader =
                                    Just
                                        { textField = headerTextField
                                        , visible = True
                                        }
                                , settings = Strapi.SectionSettings.default
                                }
                      , {}
                      )
                    ]
                , UIExplorer.storiesOf
                    "Image with Front Text"
                    [ ( "Default"
                      , \_ ->
                            Section.ImageWithFrontText.view
                                { image = image_3_2
                                , link = Nothing
                                , mediaSettings = Strapi.MediaSettings.default
                                , settings = Strapi.SectionSettings.default
                                , style = Section.ImageWithFrontText.Default
                                , text = Nothing
                                , textPosition = Section.ImageWithFrontText.Center
                                }
                      , {}
                      )
                    , ( "With Style - Brightness 90"
                      , \_ ->
                            Section.ImageWithFrontText.view
                                { image = image_3_2
                                , link = Nothing
                                , mediaSettings = Strapi.MediaSettings.default
                                , settings = Strapi.SectionSettings.default
                                , style = Section.ImageWithFrontText.Brightness90
                                , text = Nothing
                                , textPosition = Section.ImageWithFrontText.Center
                                }
                      , {}
                      )
                    , ( "With Front Text - BottomCenter"
                      , \_ ->
                            Section.ImageWithFrontText.view
                                { image = image_3_2
                                , link = Nothing
                                , mediaSettings = Strapi.MediaSettings.default
                                , settings = Strapi.SectionSettings.default
                                , style = Section.ImageWithFrontText.Default
                                , text = Just imageWithFrontText
                                , textPosition = Section.ImageWithFrontText.BottomCenter
                                }
                      , {}
                      )
                    , ( "With Front Text - BottomLeft"
                      , \_ ->
                            Section.ImageWithFrontText.view
                                { image = image_3_2
                                , link = Nothing
                                , mediaSettings = Strapi.MediaSettings.default
                                , settings = Strapi.SectionSettings.default
                                , style = Section.ImageWithFrontText.Default
                                , text = Just imageWithFrontText
                                , textPosition = Section.ImageWithFrontText.BottomLeft
                                }
                      , {}
                      )
                    , ( "With Front Text - BottomRight"
                      , \_ ->
                            Section.ImageWithFrontText.view
                                { image = image_3_2
                                , link = Nothing
                                , mediaSettings = Strapi.MediaSettings.default
                                , settings = Strapi.SectionSettings.default
                                , style = Section.ImageWithFrontText.Default
                                , text = Just imageWithFrontText
                                , textPosition = Section.ImageWithFrontText.BottomRight
                                }
                      , {}
                      )
                    , ( "With Front Text - Center"
                      , \_ ->
                            Section.ImageWithFrontText.view
                                { image = image_3_2
                                , link = Nothing
                                , mediaSettings = Strapi.MediaSettings.default
                                , settings = Strapi.SectionSettings.default
                                , style = Section.ImageWithFrontText.Default
                                , text = Just imageWithFrontText
                                , textPosition = Section.ImageWithFrontText.Center
                                }
                      , {}
                      )
                    , ( "With Front Text - CenterLeft"
                      , \_ ->
                            Section.ImageWithFrontText.view
                                { image = image_3_2
                                , link = Nothing
                                , mediaSettings = Strapi.MediaSettings.default
                                , settings = Strapi.SectionSettings.default
                                , style = Section.ImageWithFrontText.Default
                                , text = Just imageWithFrontText
                                , textPosition = Section.ImageWithFrontText.CenterLeft
                                }
                      , {}
                      )
                    , ( "With Front Text - CenterRight"
                      , \_ ->
                            Section.ImageWithFrontText.view
                                { image = image_3_2
                                , link = Nothing
                                , mediaSettings = Strapi.MediaSettings.default
                                , settings = Strapi.SectionSettings.default
                                , style = Section.ImageWithFrontText.Default
                                , text = Just imageWithFrontText
                                , textPosition = Section.ImageWithFrontText.CenterRight
                                }
                      , {}
                      )
                    , ( "With Front Text - TopCenter"
                      , \_ ->
                            Section.ImageWithFrontText.view
                                { image = image_3_2
                                , link = Nothing
                                , mediaSettings = Strapi.MediaSettings.default
                                , settings = Strapi.SectionSettings.default
                                , style = Section.ImageWithFrontText.Default
                                , text = Just imageWithFrontText
                                , textPosition = Section.ImageWithFrontText.TopCenter
                                }
                      , {}
                      )
                    , ( "With Front Text - TopLeft"
                      , \_ ->
                            Section.ImageWithFrontText.view
                                { image = image_3_2
                                , link = Nothing
                                , mediaSettings = Strapi.MediaSettings.default
                                , settings = Strapi.SectionSettings.default
                                , style = Section.ImageWithFrontText.Default
                                , text = Just imageWithFrontText
                                , textPosition = Section.ImageWithFrontText.TopLeft
                                }
                      , {}
                      )
                    , ( "With Front Text - TopRight"
                      , \_ ->
                            Section.ImageWithFrontText.view
                                { image = image_3_2
                                , link = Nothing
                                , mediaSettings = Strapi.MediaSettings.default
                                , settings = Strapi.SectionSettings.default
                                , style = Section.ImageWithFrontText.Default
                                , text = Just imageWithFrontText
                                , textPosition = Section.ImageWithFrontText.TopRight
                                }
                      , {}
                      )
                    , ( "Contain"
                      , \_ ->
                            Section.ImageWithFrontText.view
                                { image = image_1_1
                                , link = Nothing
                                , mediaSettings =
                                    { fit = Contain
                                    , format = Format_3_2
                                    }
                                , settings = Strapi.SectionSettings.default
                                , style = Section.ImageWithFrontText.Default
                                , text = Nothing
                                , textPosition = Section.ImageWithFrontText.Center
                                }
                      , {}
                      )
                    , ( "With Link"
                      , \_ ->
                            Section.ImageWithFrontText.view
                                { image = image_3_2
                                , link =
                                    Just
                                        (Strapi.Link.External
                                            { icon = Nothing
                                            , label = "I am not rendered"
                                            , newTab = True
                                            , url = "https://woc2023.ch/"
                                            }
                                        )
                                , mediaSettings = Strapi.MediaSettings.default
                                , settings = Strapi.SectionSettings.default
                                , style = Section.ImageWithFrontText.Default
                                , text = Nothing
                                , textPosition = Section.ImageWithFrontText.Center
                                }
                      , {}
                      )
                    , ( "With Front Text and Link"
                      , \_ ->
                            Section.ImageWithFrontText.view
                                { image = image_3_2
                                , link =
                                    Just
                                        (Strapi.Link.External
                                            { icon = Nothing
                                            , label = "I am not rendered"
                                            , newTab = True
                                            , url = "https://woc2023.ch/"
                                            }
                                        )
                                , mediaSettings = Strapi.MediaSettings.default
                                , settings = Strapi.SectionSettings.default
                                , style = Section.ImageWithFrontText.Default
                                , text = Just imageWithFrontText
                                , textPosition = Section.ImageWithFrontText.Center
                                }
                      , {}
                      )
                    , ( "Format 1:1"
                      , \_ ->
                            Section.ImageWithFrontText.view
                                { image = image_1_1
                                , link = Nothing
                                , mediaSettings =
                                    { fit = Cover
                                    , format = Format_1_1
                                    }
                                , settings = Strapi.SectionSettings.default
                                , style = Section.ImageWithFrontText.Default
                                , text = Nothing
                                , textPosition = Section.ImageWithFrontText.Center
                                }
                      , {}
                      )
                    , ( "Format 1:1 (3:2)"
                      , \_ ->
                            Section.ImageWithFrontText.view
                                { image = image_3_2
                                , link = Nothing
                                , mediaSettings =
                                    { fit = Cover
                                    , format = Format_1_1
                                    }
                                , settings = Strapi.SectionSettings.default
                                , style = Section.ImageWithFrontText.Default
                                , text = Nothing
                                , textPosition = Section.ImageWithFrontText.Center
                                }
                      , {}
                      )
                    , ( "Format 16:9"
                      , \_ ->
                            Section.ImageWithFrontText.view
                                { image = image_16_9
                                , link = Nothing
                                , mediaSettings =
                                    { fit = Cover
                                    , format = Format_16_9
                                    }
                                , settings = Strapi.SectionSettings.default
                                , style = Section.ImageWithFrontText.Default
                                , text = Nothing
                                , textPosition = Section.ImageWithFrontText.Center
                                }
                      , {}
                      )
                    , ( "Format 16:9 (1:1)"
                      , \_ ->
                            Section.ImageWithFrontText.view
                                { image = image_1_1
                                , link = Nothing
                                , mediaSettings =
                                    { fit = Cover
                                    , format = Format_16_9
                                    }
                                , settings = Strapi.SectionSettings.default
                                , style = Section.ImageWithFrontText.Default
                                , text = Nothing
                                , textPosition = Section.ImageWithFrontText.Center
                                }
                      , {}
                      )
                    , ( "Format 3:2"
                      , \_ ->
                            Section.ImageWithFrontText.view
                                { image = image_3_2
                                , link = Nothing
                                , mediaSettings =
                                    { fit = Cover
                                    , format = Format_16_9
                                    }
                                , settings = Strapi.SectionSettings.default
                                , style = Section.ImageWithFrontText.Default
                                , text = Nothing
                                , textPosition = Section.ImageWithFrontText.Center
                                }
                      , {}
                      )
                    , ( "Format 3:2 (1:1)"
                      , \_ ->
                            Section.ImageWithFrontText.view
                                { image = image_1_1
                                , link = Nothing
                                , mediaSettings =
                                    { fit = Cover
                                    , format = Format_16_9
                                    }
                                , settings = Strapi.SectionSettings.default
                                , style = Section.ImageWithFrontText.Default
                                , text = Nothing
                                , textPosition = Section.ImageWithFrontText.Center
                                }
                      , {}
                      )
                    ]
                , UIExplorer.storiesOf
                    "Image with Text"
                    [ ( "Default Left"
                      , \_ ->
                            Section.ImageWithText.view
                                { button = Just (internalLink "Sections" "Image with Text" "Left")
                                , buttonSecondary = Just (externalLink "https://en.wikipedia.org/wiki/Lorem_ipsum" "Lorem")
                                , image = image_3_2
                                , imagePosition = Section.ImageWithText.Left
                                , mediaSettings =
                                    { fit = Cover
                                    , format = Format_3_2
                                    }
                                , settings = Strapi.SectionSettings.default
                                , style = Section.ImageWithText.Default
                                , text = imageWithText
                                }
                      , {}
                      )
                    , ( "Default Right"
                      , \_ ->
                            Section.ImageWithText.view
                                { button = Just (internalLink "Sections" "Image with Text" "Right")
                                , buttonSecondary = Just (externalLink "https://en.wikipedia.org/wiki/Lorem_ipsum" "Lorem")
                                , image = image_3_2
                                , imagePosition = Section.ImageWithText.Right
                                , mediaSettings =
                                    { fit = Cover
                                    , format = Format_3_2
                                    }
                                , settings = Strapi.SectionSettings.default
                                , style = Section.ImageWithText.Default
                                , text = imageWithText
                                }
                      , {}
                      )
                    , ( "FullSizeImage Left"
                      , \_ ->
                            Section.ImageWithText.view
                                { button = Just (internalLink "Sections" "Image with Text" "Left")
                                , buttonSecondary = Just (externalLink "https://en.wikipedia.org/wiki/Lorem_ipsum" "Lorem")
                                , image = image_3_2
                                , imagePosition = Section.ImageWithText.Left
                                , mediaSettings =
                                    { fit = Cover
                                    , format = Format_3_2
                                    }
                                , settings = Strapi.SectionSettings.default
                                , style = Section.ImageWithText.FullSizeImage
                                , text = imageWithText
                                }
                      , {}
                      )
                    , ( "FullSizeImage Right"
                      , \_ ->
                            Section.ImageWithText.view
                                { button = Just (internalLink "Sections" "Image with Text" "Right")
                                , buttonSecondary = Just (externalLink "https://en.wikipedia.org/wiki/Lorem_ipsum" "Lorem")
                                , image = image_3_2
                                , imagePosition = Section.ImageWithText.Right
                                , mediaSettings =
                                    { fit = Cover
                                    , format = Format_3_2
                                    }
                                , settings = Strapi.SectionSettings.default
                                , style = Section.ImageWithText.FullSizeImage
                                , text = imageWithText
                                }
                      , {}
                      )
                    , ( "CroppedImage Left"
                      , \_ ->
                            Section.ImageWithText.view
                                { button = Just (internalLink "Sections" "Image with Text" "Left")
                                , buttonSecondary = Just (externalLink "https://en.wikipedia.org/wiki/Lorem_ipsum" "Lorem")
                                , image = image_3_2
                                , imagePosition = Section.ImageWithText.Left
                                , mediaSettings =
                                    { fit = Cover
                                    , format = Format_3_2
                                    }
                                , settings = Strapi.SectionSettings.default
                                , style = Section.ImageWithText.CroppedImage
                                , text = imageWithText
                                }
                      , {}
                      )
                    , ( "CroppedImage Right"
                      , \_ ->
                            Section.ImageWithText.view
                                { button = Just (internalLink "Sections" "Image with Text" "Right")
                                , buttonSecondary = Just (externalLink "https://en.wikipedia.org/wiki/Lorem_ipsum" "Lorem")
                                , image = image_3_2
                                , imagePosition = Section.ImageWithText.Right
                                , mediaSettings =
                                    { fit = Cover
                                    , format = Format_3_2
                                    }
                                , settings = Strapi.SectionSettings.default
                                , style = Section.ImageWithText.CroppedImage
                                , text = imageWithText
                                }
                      , {}
                      )
                    , ( "Contain"
                      , \_ ->
                            Section.ImageWithText.view
                                { button = Just (internalLink "Sections" "Image with Text" "Left")
                                , buttonSecondary = Just (externalLink "https://en.wikipedia.org/wiki/Lorem_ipsum" "Lorem")
                                , image = image_1_1
                                , imagePosition = Section.ImageWithText.Left
                                , mediaSettings =
                                    { fit = Cover
                                    , format = Format_3_2
                                    }
                                , settings = Strapi.SectionSettings.default
                                , style = Section.ImageWithText.Default
                                , text = imageWithText
                                }
                      , {}
                      )
                    , ( "Format 1:1"
                      , \_ ->
                            Section.ImageWithText.view
                                { button = Just (internalLink "Sections" "Image with Text" "Left")
                                , buttonSecondary = Just (externalLink "https://en.wikipedia.org/wiki/Lorem_ipsum" "Lorem")
                                , image = image_1_1
                                , imagePosition = Section.ImageWithText.Left
                                , mediaSettings =
                                    { fit = Cover
                                    , format = Format_1_1
                                    }
                                , settings = Strapi.SectionSettings.default
                                , style = Section.ImageWithText.Default
                                , text = imageWithText
                                }
                      , {}
                      )
                    , ( "Format 1:1 (3:2)"
                      , \_ ->
                            Section.ImageWithText.view
                                { button = Just (internalLink "Sections" "Image with Text" "Left")
                                , buttonSecondary = Just (externalLink "https://en.wikipedia.org/wiki/Lorem_ipsum" "Lorem")
                                , image = image_3_2
                                , imagePosition = Section.ImageWithText.Left
                                , mediaSettings =
                                    { fit = Cover
                                    , format = Format_1_1
                                    }
                                , settings = Strapi.SectionSettings.default
                                , style = Section.ImageWithText.Default
                                , text = imageWithText
                                }
                      , {}
                      )
                    , ( "Format 16:9"
                      , \_ ->
                            Section.ImageWithText.view
                                { button = Just (internalLink "Sections" "Image with Text" "Left")
                                , buttonSecondary = Just (externalLink "https://en.wikipedia.org/wiki/Lorem_ipsum" "Lorem")
                                , image = image_16_9
                                , imagePosition = Section.ImageWithText.Left
                                , mediaSettings =
                                    { fit = Cover
                                    , format = Format_16_9
                                    }
                                , settings = Strapi.SectionSettings.default
                                , style = Section.ImageWithText.Default
                                , text = imageWithText
                                }
                      , {}
                      )
                    , ( "Format 16:9 (1:1)"
                      , \_ ->
                            Section.ImageWithText.view
                                { button = Just (internalLink "Sections" "Image with Text" "Left")
                                , buttonSecondary = Just (externalLink "https://en.wikipedia.org/wiki/Lorem_ipsum" "Lorem")
                                , image = image_1_1
                                , imagePosition = Section.ImageWithText.Left
                                , mediaSettings =
                                    { fit = Cover
                                    , format = Format_16_9
                                    }
                                , settings = Strapi.SectionSettings.default
                                , style = Section.ImageWithText.Default
                                , text = imageWithText
                                }
                      , {}
                      )
                    , ( "Format 3:2"
                      , \_ ->
                            Section.ImageWithText.view
                                { button = Just (internalLink "Sections" "Image with Text" "Left")
                                , buttonSecondary = Just (externalLink "https://en.wikipedia.org/wiki/Lorem_ipsum" "Lorem")
                                , image = image_16_9
                                , imagePosition = Section.ImageWithText.Left
                                , mediaSettings =
                                    { fit = Cover
                                    , format = Format_16_9
                                    }
                                , settings = Strapi.SectionSettings.default
                                , style = Section.ImageWithText.Default
                                , text = imageWithText
                                }
                      , {}
                      )
                    , ( "Format 3:2 (1:1)"
                      , \_ ->
                            Section.ImageWithText.view
                                { button = Just (internalLink "Sections" "Image with Text" "Left")
                                , buttonSecondary = Just (externalLink "https://en.wikipedia.org/wiki/Lorem_ipsum" "Lorem")
                                , image = image_1_1
                                , imagePosition = Section.ImageWithText.Left
                                , mediaSettings =
                                    { fit = Cover
                                    , format = Format_16_9
                                    }
                                , settings = Strapi.SectionSettings.default
                                , style = Section.ImageWithText.Default
                                , text = imageWithText
                                }
                      , {}
                      )
                    ]
                , UIExplorer.storiesOf
                    "Info Text"
                    [ ( "Primary"
                      , \_ ->
                            Section.InfoText.view
                                { onAbbrev = Nothing
                                }
                                { settings = Strapi.SectionSettings.default
                                , style = Section.InfoText.Primary
                                , text = infoText
                                }
                      , {}
                      )
                    , ( "Secondary"
                      , \_ ->
                            Section.InfoText.view
                                { onAbbrev = Nothing
                                }
                                { settings = Strapi.SectionSettings.default
                                , style = Section.InfoText.Secondary
                                , text = infoText
                                }
                      , {}
                      )
                    , ( "Tertiary"
                      , \_ ->
                            Section.InfoText.view
                                { onAbbrev = Nothing
                                }
                                { settings = Strapi.SectionSettings.default
                                , style = Section.InfoText.Tertiary
                                , text = infoText
                                }
                      , {}
                      )
                    , ( "Warning"
                      , \_ ->
                            Section.InfoText.view
                                { onAbbrev = Nothing
                                }
                                { settings = Strapi.SectionSettings.default
                                , style = Section.InfoText.Warning
                                , text = infoText
                                }
                      , {}
                      )
                    ]
                , UIExplorer.storiesOf
                    "Navigation"
                    [ ( "Default"
                      , \_ ->
                            Section.Navigation.view
                                { links =
                                    List.concat
                                        (List.repeat 4
                                            [ ( internalLink "Sections" "Navigation" "Lorem ipsum", Just "Lorem ipsum dolor sit amet, consectetur adipisici elit, …" )
                                            , ( externalLink "https://en.wikipedia.org/wiki/Lorem_ipsum" "Lorem ipsum", Nothing )
                                            ]
                                        )
                                , settings = Strapi.SectionSettings.default
                                }
                      , {}
                      )
                    ]
                , let
                    data =
                        { sectionHeader = Nothing
                        , settings = Strapi.SectionSettings.default
                        }
                  in
                  UIExplorer.storiesOf
                    "Newsletter Signup"
                    [ ( "Default"
                      , \{ customModel } ->
                            Section.NewsletterSignup.view
                                { locale = Locale.De
                                }
                                customModel.newsletterSignup
                                |> Html.map UpdateNewsletterSignup
                      , {}
                      )
                    , ( "With Header"
                      , \{ customModel } ->
                            Section.NewsletterSignup.view
                                { locale = Locale.De
                                }
                                customModel.newsletterSignupWithHeader
                                |> Html.map UpdateNewsletterSignup
                      , {}
                      )
                    , ( "Submitting"
                      , \{ customModel } ->
                            Section.NewsletterSignup.view
                                { locale = Locale.De
                                }
                                (Section.NewsletterSignup.Model data
                                    { input = Section.NewsletterSignup.initialInput
                                    , lastSubmission = Nothing
                                    , showValidationErrors = False
                                    , submitting = True
                                    }
                                )
                                |> Html.map (\_ -> Ignored)
                      , {}
                      )
                    , ( "Validating"
                      , \{ customModel } ->
                            Section.NewsletterSignup.view
                                { locale = Locale.De
                                }
                                (Section.NewsletterSignup.Model data
                                    { input = Section.NewsletterSignup.initialInput
                                    , lastSubmission = Nothing
                                    , showValidationErrors = True
                                    , submitting = False
                                    }
                                )
                                |> Html.map (\_ -> Ignored)
                      , {}
                      )
                    , ( "Success"
                      , \{ customModel } ->
                            Section.NewsletterSignup.view
                                { locale = Locale.De
                                }
                                (Section.NewsletterSignup.Model data
                                    { input = Section.NewsletterSignup.initialInput
                                    , lastSubmission = Just (Ok ())
                                    , showValidationErrors = False
                                    , submitting = False
                                    }
                                )
                                |> Html.map (\_ -> Ignored)
                      , {}
                      )
                    , ( "Error"
                      , \{ customModel } ->
                            Section.NewsletterSignup.view
                                { locale = Locale.De
                                }
                                (Section.NewsletterSignup.Model data
                                    { input = Section.NewsletterSignup.initialInput
                                    , lastSubmission = Just (Err Http.NetworkError)
                                    , showValidationErrors = False
                                    , submitting = False
                                    }
                                )
                                |> Html.map (\_ -> Ignored)
                      , {}
                      )
                    ]
                , UIExplorer.storiesOf
                    "Search"
                    [ ( "Default"
                      , \{ customModel } ->
                            Section.Search.view
                                { host = "https://cms.woc2023.app/search"
                                , publicKey = "cabdb657bbff177a4ddf8d92dba6588418262dee069e720a6cadabff254bb0da"
                                }
                                []
                                customModel.search
                                |> Html.map UpdateSearch
                      , {}
                      )
                    ]
                , UIExplorer.storiesOf
                    "Simple Centered"
                    [ ( "Default"
                      , \_ ->
                            Section.SimpleCentered.view
                                { above = "Lorem ipsum"
                                , below = "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
                                , settings = Strapi.SectionSettings.default
                                , tagline = "Lorem ipsum dolor sit amet"
                                }
                      , {}
                      )
                    ]
                , UIExplorer.storiesOf
                    "Simple Three Column"
                    [ ( "Default"
                      , \_ ->
                            let
                                column =
                                    { description = "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                                    , icon = icon
                                    , name = "Lorem ipsum"
                                    }
                            in
                            Section.SimpleThreeColumn.view
                                { column1 = column
                                , column2 = column
                                , column3 = column
                                , sectionHeader = Nothing
                                , settings = Strapi.SectionSettings.default
                                }
                      , {}
                      )
                    , ( "With Header"
                      , \_ ->
                            let
                                column =
                                    { description = "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                                    , icon = icon
                                    , name = "Lorem ipsum"
                                    }
                            in
                            Section.SimpleThreeColumn.view
                                { column1 = column
                                , column2 = column
                                , column3 = column
                                , sectionHeader =
                                    Just
                                        { textField = headerTextField
                                        , visible = True
                                        }
                                , settings = Strapi.SectionSettings.default
                                }
                      , {}
                      )
                    ]
                , UIExplorer.storiesOf
                    "Slider"
                    [ ( "Default"
                      , \_ ->
                            Section.Slider.view
                                { cards =
                                    List.repeat 6
                                        { buttonLabel = Just "Visit"
                                        , image = image_3_2
                                        , link = Just "#Sections/Slider/Default"
                                        , textField = "I am card"
                                        }
                                , sectionHeader = Nothing
                                , settings = Strapi.SectionSettings.default
                                }
                      , {}
                      )
                    , ( "With Header"
                      , \_ ->
                            Section.Slider.view
                                { cards =
                                    List.repeat 6
                                        { buttonLabel = Just "Visit"
                                        , image = image_3_2
                                        , link = Just "#Sections/Slider/Default"
                                        , textField = "I am card"
                                        }
                                , sectionHeader =
                                    Just
                                        { textField = headerTextField
                                        , visible = True
                                        }
                                , settings = Strapi.SectionSettings.default
                                }
                      , {}
                      )
                    ]
                , UIExplorer.storiesOf
                    "Space"
                    [ ( "Default"
                      , \_ ->
                            Section.Space.view
                                { rems = 1
                                , settings = Strapi.SectionSettings.default
                                }
                      , {}
                      )
                    ]
                , UIExplorer.storiesOf
                    "Text"
                    [ ( "Default"
                      , \_ ->
                            Section.Text.view
                                { onAbbrev = Just AdditionalTextOpened
                                }
                                { settings = Strapi.SectionSettings.default
                                , text = markdown
                                }
                      , {}
                      )
                    ]
                , UIExplorer.storiesOf
                    "Timeline"
                    [ ( "Default"
                      , \_ ->
                            Section.Timeline.view
                                { entries = List.repeat 4 timelineText
                                , sectionHeader = Nothing
                                , settings = Strapi.SectionSettings.default
                                }
                      , {}
                      )
                    , ( "With Header"
                      , \_ ->
                            Section.Timeline.view
                                { entries = List.repeat 4 timelineText
                                , sectionHeader =
                                    Just
                                        { textField = headerTextField
                                        , visible = True
                                        }
                                , settings = Strapi.SectionSettings.default
                                }
                      , {}
                      )
                    ]
                ]
        )



-- DEMO PAGES


landingPageTextBlock : String
landingPageTextBlock =
    """
#### Lorem ipsumt

[Lorem ipsum](https://de.wikipedia.org/wiki/Lorem_ipsum) dolor sit amet, [consectetur](#Sections/Cards/Default) adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.

- Lorem ipsum dolor sit amet
- Lorem ipsum dolor sit amet
- Lorem ipsum dolor sit amet

Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.

1. Lorem ipsum dolor sit amet
2. Lorem ipsum dolor sit amet
3. Lorem ipsum dolor sit amet
    """


examplePage1 : Html Msg
examplePage1 =
    let
        imageSection =
            Section.ImageWithFrontText.view
                { image = image_16_9
                , link = Nothing
                , mediaSettings =
                    { fit = Cover
                    , format = Format_16_9
                    }
                , settings = Strapi.SectionSettings.default
                , style = Section.ImageWithFrontText.Default
                , text = Nothing
                , textPosition = Section.ImageWithFrontText.Center
                }

        textSection =
            Section.Text.view
                { onAbbrev = Just AdditionalTextOpened
                }
                { settings = Strapi.SectionSettings.default
                , text = landingPageTextBlock
                }
    in
    Section.Section.default
        [ Html.Attributes.class "page"
        ]
        [ imageSection
        , textSection
        ]
        |> Section.Section.view


examplePage2 : Html Msg
examplePage2 =
    let
        imageWithTextSection =
            Section.ImageWithText.view
                { button = Just (internalLink "Sections" "Image with Text" "Right")
                , buttonSecondary = Just (externalLink "https://en.wikipedia.org/wiki/Lorem_ipsum" "Lorem")
                , image = image_3_2
                , imagePosition = Section.ImageWithText.Right
                , mediaSettings = Strapi.MediaSettings.default
                , settings = Strapi.SectionSettings.default
                , style = Section.ImageWithText.Default
                , text = imageWithText
                }

        textSection =
            Section.Text.view
                { onAbbrev = Just AdditionalTextOpened
                }
                { settings = Strapi.SectionSettings.default
                , text = landingPageTextBlock
                }
    in
    Section.Section.default
        [ Html.Attributes.class "page"
        ]
        [ imageWithTextSection
        , textSection
        ]
        |> Section.Section.view



-- DEMO DATA


currentUrl : Url
currentUrl =
    { fragment = Just "Navigation/Drawer/Default"
    , host = "localhost"
    , path = "/"
    , port_ = Nothing
    , protocol = Url.Http
    , query = Nothing
    }


icon : String
icon =
    "fa-regular fa-ghost"


image :
    { height : Int
    , width : Int
    }
    -> Strapi.Image.Image
image config =
    let
        f { height, width } =
            "https://placekitten.com/"
                ++ String.fromInt width
                ++ "/"
                ++ String.fromInt height
    in
    Strapi.Image.generate { alternativeText = Just "A cute kitten" }
        config
        (f config)
        (\dimensions -> Just (f dimensions))


images : List Strapi.Image.Image
images =
    Tuple.first nonEmptyImages :: Tuple.second nonEmptyImages


nonEmptyImages : ( Strapi.Image.Image, List Strapi.Image.Image )
nonEmptyImages =
    ( image_3_2
    , [ image_1_1
      , image_16_9
      , image_2_3
      , image_9_16
      ]
    )


nonEmptyCaptionedImages : ( Section.ImageList.CaptionedImage, List Section.ImageList.CaptionedImage )
nonEmptyCaptionedImages =
    let
        toCaptionedImage image_ =
            { caption = Just "Lorem ipsum"
            , image = image_
            }
    in
    (Tuple.mapFirst toCaptionedImage
        << Tuple.mapSecond (List.map toCaptionedImage)
    )
        nonEmptyImages


image_1_1 : Strapi.Image.Image
image_1_1 =
    image
        { height = 2000
        , width = 2000
        }


image_16_9 : Strapi.Image.Image
image_16_9 =
    image
        { height = 1800
        , width = 3200
        }


image_2_3 : Strapi.Image.Image
image_2_3 =
    image
        { height = 3000
        , width = 2000
        }


image_3_2 : Strapi.Image.Image
image_3_2 =
    image
        { height = 2000
        , width = 3000
        }


image_9_16 : Strapi.Image.Image
image_9_16 =
    image
        { height = 3200
        , width = 1800
        }


internalLink : String -> String -> String -> Strapi.Link.Link
internalLink category name label =
    Strapi.Link.Internal
        { icon = Just icon
        , label = label
        , slug = [ "#" ++ category, name, label ]
        }


externalLink : String -> String -> Strapi.Link.Link
externalLink url label =
    Strapi.Link.External
        { icon = Nothing
        , label = label
        , newTab = True
        , url = url
        }


cardText : String
cardText =
    """
##### Lorem ipsum
###### Dolor sit amet

[Lorem ipsum](https://de.wikipedia.org/wiki/Lorem_ipsum) dolor sit amet, [consectetur](#Sections/Cards/Default) adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.

Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquid ex ea commodi consequat.
    """


iconTextColumnsText : String
iconTextColumnsText =
    """
###### Lorem ipsumt dolor

Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.
    """


imageWithFrontText : String
imageWithFrontText =
    """
### Lorem ipsumt dolor

Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
    """


imageWithText : String
imageWithText =
    """
### Lorem ipsumt dolor

Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
    """


timelineText : String
timelineText =
    """
##### 01.01.2022

Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.
    """


headerTextField : String
headerTextField =
    """
# Lorem ipsumt dolor

Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.
    """


infoText : String
infoText =
    """
# Lorem ipsumt dolor

Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.
    """


markdown : String
markdown =
    """
Find more information about markdown on [markdownguide.org](https://www.markdownguide.org/cheat-sheet)

---

#### Blockquote

```markdown
> blockquote
```

&nbsp;

> blockquote

&nbsp;

---

#### Checklist

```markdown
- [x] Write the press release
- [ ] Update the website
- [ ] Contact the media
```

&nbsp;

- [x] Write the press release
- [ ] Update the website
- [ ] Contact the media

&nbsp;

---

#### Code block

```markdown
{
  "firstName": "John",
  "lastName": "Smith",
  "age": 25
}
```

&nbsp;

JSON example
```json
{
  "firstName": "John",
  "lastName": "Smith",
  "age": 25
}
```

&nbsp;

---

#### Definition List

```markdown
term
: definition
```

&nbsp;

term
: definition

&nbsp;

---

#### Emoji

```markdown
That is so funny! :joy:
```

&nbsp;

That is so funny! :joy:

&nbsp;

---

#### Emphasis

```markdown
**bold text**

*italicized text*
```

&nbsp;

**bold text**

*italicized text*

&nbsp;

---

#### Footnote

```markdown
Here's a sentence with a footnote. [^1]

[^1]: This is the footnote.
```

&nbsp;

Here's a sentence with a footnote. [^1]

[^1]: This is the footnote.

&nbsp;

---

#### Headers

```markdown
# Heading 1
## Heading 2
### Heading 3
#### Heading 4
##### Heading 5
###### Heading 6
```

&nbsp;

# Heading 1
## Heading 2
### Heading 3
#### Heading 4
##### Heading 5
###### Heading 6

&nbsp;

---

#### Heading ID

```markdown
###### My Great Heading {#custom-id}
```

&nbsp;

###### My Great Heading {#custom-id}

&nbsp;

---

#### Highlight

```markdown
I need to highlight these ==very important words==.
```

&nbsp;

I need to highlight these ==very important words==.

&nbsp;

---

#### Horizontal bar

```markdown
---
***
___
```

&nbsp;

---
***
___

&nbsp;

---

#### Image

```markdown
![alt text](https://placekitten.com/300/200)
```

&nbsp;

![alt text](https://placekitten.com/300/200)

&nbsp;

#### Inline code

```markdown
`code`
```

&nbsp;

`code`

&nbsp;

---

#### Link

```markdown
[title](https://www.example.com)
```

&nbsp;

[title](https://www.example.com)

&nbsp;

---

#### Icons

```markdown
Follow us on LinkedIn [<i class="fa-brands fa-linkedin"></i>](#)
```

&nbsp;

Follow us on LinkedIn [<i class="fa-brands fa-linkedin"></i>](#)

&nbsp;

---

#### List

```markdown
1. First item
2. Second item
3. Third item

- First item
- Second item
- Third item
```

&nbsp;

1. First item
2. Second item
3. Third item

- First item
- Second item
- Third item

&nbsp;

---

#### Paragraph

```markdown
Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
```

&nbsp;

Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.

&nbsp;

---

#### Subscript

```markdown
H~2~O
```

&nbsp;

H~2~O

&nbsp;

---

#### Superscript

```markdown
X^2^
```

&nbsp;

X^2^

&nbsp;

---

#### Strikethrough

```markdown
~~The world is flat.~~
```

&nbsp;

~~The world is flat.~~

&nbsp;

---

#### Centered text

```markdown
<center>
##### A centered heading.

> with a centered blockquote below
</center>
```

&nbsp;

<center>
##### A centered heading.

> with a centered blockquote below
</center>

---

#### Additional text

```markdown
Der InnHub La Punt wird vom Traum zur Realität…

Der InnHub ist ein einzigartiger Ort für <additional-text text="Der InnHub ermöglicht zielgerichtete Arbeit und Vernetzung in einem naturnahen Rückzugsort. Einzelpersonen, die das Engadin als Inspirationsort nutzen wollen, finden in unserem Coworking Bereich die ideale Arbeitsumgebung.">Co-Creation</additional-text>, wo an Themen gearbeitet wird für eine bessere Zukunft inmitten atemberaubender Natur, und funktioniert als Third Place, Seasonal Headquarters und Catalyst.
```

&nbsp;

Der InnHub La Punt wird vom Traum zur Realität…

Der InnHub ist ein einzigartiger Ort für <additional-text text="Der InnHub ermöglicht zielgerichtete Arbeit und Vernetzung in einem naturnahen Rückzugsort. Einzelpersonen, die das Engadin als Inspirationsort nutzen wollen, finden in unserem Coworking Bereich die ideale Arbeitsumgebung.">Co-Creation</additional-text>, wo an Themen gearbeitet wird für eine bessere Zukunft inmitten atemberaubender Natur, und funktioniert als Third Place, Seasonal Headquarters und Catalyst.

---

#### Table

```markdown
| Syntax | Description |
| ----------- | ----------- |
| Header | Title |
| Paragraph | Text |
```

&nbsp;

| Syntax | Description |
| ----------- | ----------- |
| Header | Title |
| Paragraph | Text |

&nbsp;
    """
